import { PaymentMethodBase } from 'domains/account/components'
import { Chip, StepHeader } from 'salad/components'
import * as Icons from 'salad/icons'
import { StepLayout } from 'salad/layouts'
import { VStack } from 'salad/primitives'
import { ApronFooter } from '../components/apron-footer/apron-footer'
import type { PaymentMethodStepValues } from '../types'

interface PaymentMethodStepProps {
  onContinue: (values: PaymentMethodStepValues) => void
  onBack: () => void
}

export const PaymentMethodStep = ({ onBack, onContinue }: PaymentMethodStepProps) => {
  return (
    <StepLayout.Root css={{ gridTemplateRows: 'auto auto 1fr' }}>
      <StepLayout.Header>
        <StepHeader.Root onBack={onBack}>
          <StepHeader.Title>Choose your payment method</StepHeader.Title>
        </StepHeader.Root>
      </StepLayout.Header>
      <VStack gap={16}>
        <PaymentMethodBase.Root
          surface="card"
          onClick={() => onContinue({ paymentMethod: 'BANK' })}
        >
          <PaymentMethodBase.Start>
            <Icons.S32.Bank />
          </PaymentMethodBase.Start>
          <PaymentMethodBase.Title>Pay by bank</PaymentMethodBase.Title>
        </PaymentMethodBase.Root>
        <PaymentMethodBase.Root
          surface="card"
          onClick={() => onContinue({ paymentMethod: 'CARD' })}
        >
          <PaymentMethodBase.Start>
            <Icons.S32.Card />
          </PaymentMethodBase.Start>
          <PaymentMethodBase.Title>Pay by card</PaymentMethodBase.Title>
          <PaymentMethodBase.End>
            <Chip variant="neutral">2.9% fee</Chip>
          </PaymentMethodBase.End>
        </PaymentMethodBase.Root>
      </VStack>
      <ApronFooter />
    </StepLayout.Root>
  )
}
