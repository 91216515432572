import { useGetPaidPayrun } from 'domains/payrun/queries'
import { StorageKey } from 'kitchen/constants'
import { useForm, yupResolver } from 'kitchen/forms'
import type { UUID } from 'kitchen/types'
import { assert, createUUID } from 'kitchen/utils/helpers'
import * as yup from 'kitchen/validations'
import { useId, useState } from 'react'
import { Input, InputGroup, StepHeader, Surface } from 'salad/components'
import { StepLayout } from 'salad/layouts'
import type { PayStepValues } from '../types'
import { usePay } from '../use-pay'

const validationSchema = yup.object({
  email: yup.email(),
})

interface PayStepProps {
  values: PayStepValues
  onContinue: (values: PayStepValues) => void
  onBack: () => void
}

export const PayByBankStep = ({ values, onBack, onContinue }: PayStepProps) => {
  const formId = useId()

  const [idempotencyKey] = useState(() => createUUID<UUID>())

  const { handleSubmit, register, formState, getValues } = useForm<PayStepValues>({
    defaultValues: values,
    resolver: yupResolver(validationSchema),
  })

  assert(values.payrunId, 'Missing payrun id')
  const getPaidPayrun = useGetPaidPayrun({ payrunId: values.payrunId })

  const [pay] = usePay({
    onSuccess: async () => {
      const updatedPayrun = await getPaidPayrun.refetch()
      assert(updatedPayrun.data, 'Missing payrun')

      onContinue({
        ...values,
        ...updatedPayrun.data,
        email: getValues().email,
      })
    },
  })

  function handleFormSubmit(values: PayStepValues) {
    localStorage.setItem(StorageKey.PLAID_PAYER_EMAIL, getValues().email)

    assert(values.payrunId, 'Link is not valid. Payrun details are missing')
    assert(values.paymentMethod, 'Link is not valid. Payment method is missing')

    pay.mutate({
      payrunId: values.payrunId,
      idempotencyKey: idempotencyKey,
      paymentType: values.paymentMethod,
    })
  }

  return (
    <StepLayout.Root>
      <StepLayout.Header>
        <StepHeader.Root onBack={onBack}>
          <StepHeader.Title>Provide your email address</StepHeader.Title>
          <StepHeader.Subtitle>We’ll use this to send you a receipt.</StepHeader.Subtitle>
        </StepHeader.Root>
      </StepLayout.Header>
      <Surface
        p="32"
        variant="flat"
        as="form"
        id={formId}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <InputGroup.Root>
          <InputGroup.Label>Email</InputGroup.Label>
          <Input
            placeholder="example@email.com"
            {...register('email')}
            inputMode="email"
            autoComplete="email"
            autoCapitalize="none"
            aria-invalid={formState.errors.email !== undefined}
          />
          <InputGroup.Message>{formState.errors.email?.message}</InputGroup.Message>
        </InputGroup.Root>
      </Surface>
      <StepLayout.Action type="submit" form={formId}>
        Pay
      </StepLayout.Action>
    </StepLayout.Root>
  )
}
