import type { AccountType } from 'api/types/accounts'
import type { CardScheme } from 'api/types/cards'
import * as formats from './formats'
import * as regex from './regex'

export interface ParsedAccountDetails {
  sortCode: string
  accountNumber: string
}

export function parseAccountDetails(
  input: string | null | undefined
): ParsedAccountDetails {
  if (!input) {
    return {
      sortCode: '',
      accountNumber: '',
    }
  }

  const number = input.replace(regex.nonDigits, '')

  return {
    sortCode: number.substring(0, 6),
    accountNumber: number.substring(6),
  }
}

export function formatAccountDetails(
  input: ParsedAccountDetails | string | null | undefined
) {
  if (!input) {
    return ''
  }

  const parsed = typeof input === 'string' ? parseAccountDetails(input) : input
  return formats.chain([formats.sortCode(parsed.sortCode), parsed.accountNumber])
}

export function toBankAccountNumber({
  sortCode,
  accountNumber,
}: Partial<ParsedAccountDetails>) {
  if (!sortCode || !accountNumber) {
    return ''
  }
  const sanitizedSortCode = sortCode.replace(regex.nonDigits, '')
  const sanitizedAccountNumber = accountNumber.replace(regex.nonDigits, '')

  return sanitizedSortCode + sanitizedAccountNumber
}

export function getAccountTypeLabel(type: AccountType) {
  switch (type) {
    case 'CARD':
      return 'Card'
    case 'BANK':
      return 'Bank'
    default:
      return 'Unknown'
  }
}

export function getCardSchemeLabel(scheme: CardScheme) {
  switch (scheme) {
    case 'MASTER_CARD':
      return 'Mastercard'
    case 'VISA':
      return 'Visa'
    case 'AMEX':
      return 'Amex'
    default:
      return 'Unknown'
  }
}
