import { theme } from '../../stitches'

export const MasterCard = () => (
  <svg width="36" height="18" viewBox="0 0 36 18" fill="none">
    <path
      d="M18 2.75464C16.1711 4.22076 15 6.47362 15 9.00001C15 11.5264 16.1711 13.7793 18 15.2454C16.6304 16.3433 14.8919 17 13 17C8.58172 17 5 13.4183 5 9.00001C5 4.58173 8.58172 1.00001 13 1.00001C14.8919 1.00001 16.6304 1.65673 18 2.75464Z"
      fill={theme.colors['grey-20'].toString()}
    />
    <path
      d="M18 15.2454C19.8289 13.7792 21 11.5264 21 9C21 6.47362 19.8289 4.22075 18 2.75463C16.1711 4.22075 15 6.47362 15 9C15 11.5264 16.1711 13.7792 18 15.2454Z"
      fill={theme.colors['grey-15'].toString()}
    />
    <path
      d="M18 15.2454C19.8289 13.7792 21 11.5264 21 9C21 6.47362 19.8289 4.22075 18 2.75463C19.3696 1.65672 21.1081 1 23 1C27.4183 1 31 4.58172 31 9C31 13.4183 27.4183 17 23 17C21.1081 17 19.3696 16.3433 18 15.2454Z"
      fill={theme.colors['grey-10'].toString()}
    />
  </svg>
)
