import { ExternalResource } from 'kitchen/constants'
import { AnimatedSuccessIcon, Button, Link, Status, Surface } from 'salad/components'
import { StatusLayout } from 'salad/layouts'
import { Text, VStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { ApronFooter } from '../components/apron-footer/apron-footer'
import { PaymentSummaryCard } from '../payment-summary-card'
import type { PaymentFlowValues } from '../types'

interface SuccessResultStepProps {
  values: PaymentFlowValues
}

export const SuccessResultStep = ({ values }: SuccessResultStepProps) => {
  return (
    <StatusLayout.Root size={926}>
      <Status.Root css={{ gridArea: 'header' }}>
        <Status.Icon>
          <AnimatedSuccessIcon />
        </Status.Icon>
        <Status.Title>Ding! It’s done</Status.Title>
      </Status.Root>
      <StatusLayout.Content>
        <VStack gap={32} css={{ marginBlockStart: theme.space[32] }}>
          <VStack
            gap={32}
            css={{
              maxWidth: 926,
              alignItems: 'flex-start',
              '@bp2': {
                gridAutoFlow: 'column',
                gridTemplateColumns: '1fr 1fr',
              },
            }}
          >
            <PaymentSummaryCard values={values} />
            <Surface p={32} css={{ gridRow: 1 }}>
              <VStack gap={32}>
                <VStack gap={8}>
                  <Text variant="headline-h2">Never waste time again</Text>
                  <Text variant="paragraph-16">
                    With Apron, payments are always that easy, approvals are smoothed out
                    and reconciliation is automatic!
                  </Text>
                </VStack>
                <VStack gap={10}>
                  <Button.Root
                    variant="main"
                    size="medium"
                    onClick={() => {
                      window.open(ExternalResource.MAIN_APP, '_blank')
                    }}
                  >
                    Try Apron now
                  </Button.Root>
                  <Link
                    as={Button.Root}
                    onClick={() => {
                      window.open(ExternalResource.APRON_WEBSITE, '_blank')
                    }}
                  >
                    Learn about Apron
                  </Link>
                </VStack>
              </VStack>
            </Surface>
          </VStack>
          <ApronFooter />
        </VStack>
      </StatusLayout.Content>
    </StatusLayout.Root>
  )
}
