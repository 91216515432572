import { useBeneficiaryCompany } from 'domains/company/queries'
import type { PayrunId } from 'domains/payrun/types'
import { StorageKey } from 'kitchen/constants'
import { useParams, useSearchParams } from 'kitchen/router'
import { assert, normalizeEmail, toType } from 'kitchen/utils/helpers'
import { Routes, Route } from 'react-router'
import { Spinner, Status } from 'salad/components'
import * as Icons from 'salad/icons'
import { StatusLayout } from 'salad/layouts'
import { PaymentFlow } from './payment-flow'
import type { PaymentFlowValues } from './types'

export const PaymentFlowPage = () => {
  const [searchParams] = useSearchParams()
  const queryParams = {
    email: normalizeEmail(searchParams.get('email') ?? ''),
    amount: searchParams.get('amount') ? Number(searchParams.get('amount')) : undefined,
    billReference: searchParams.get('reference') ?? '',
    payrunId: toType<PayrunId>(searchParams.get('payrunId')) || undefined,
  }

  const { companyAliasName } = useParams<{ companyAliasName: string }>()
  assert(companyAliasName)

  const beneficiaryCompany = useBeneficiaryCompany({ companyAliasName })

  switch (beneficiaryCompany.status) {
    case 'loading':
      return (
        <StatusLayout.Root>
          <StatusLayout.Content>
            <Status.Root>
              <Status.Icon>
                <Spinner size={64} color="grey-10" />
              </Status.Icon>
            </Status.Root>
          </StatusLayout.Content>
        </StatusLayout.Root>
      )
    case 'error':
      return (
        <StatusLayout.Root>
          <StatusLayout.Content>
            <Status.Root>
              <Status.Icon>
                <Icons.S64.Failure />
              </Status.Icon>
              <Status.Title>Something went wrong</Status.Title>
              <Status.Description>
                Failed to fetch supplier details. Please check the link is valid.
              </Status.Description>
            </Status.Root>
          </StatusLayout.Content>
        </StatusLayout.Root>
      )
    case 'success': {
      const initialValues: PaymentFlowValues = {
        beneficiaryCompany: beneficiaryCompany.data,
        email:
          queryParams.email || localStorage.getItem(StorageKey.PLAID_PAYER_EMAIL) || '',
        paymentMethod: undefined,
        amount: queryParams.amount
          ? { amount: queryParams.amount, currency: 'GBP' }
          : { amount: 0, currency: 'GBP' },
        billReference: queryParams.billReference,
        payrunId: queryParams.payrunId,
        paidAt: null,
      }

      return (
        <Routes>
          <Route index element={<PaymentFlow initialValues={initialValues} />} />
        </Routes>
      )
    }
  }
}
