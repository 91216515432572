import { useGetPaidPayrun } from 'domains/payrun/queries'
import { useSearchParams } from 'kitchen/router'
import { ImpossibleError } from 'kitchen/utils/error'
import { useEffect, useState } from 'react'
import {
  EntryStep,
  FailureResultStep,
  PayByBankStep,
  PayByCardStep,
  PaymentMethodStep,
  SuccessResultStep,
} from './steps'
import type { PaymentFlowStep, PaymentFlowValues } from './types'

interface PaymentFlowState {
  step: PaymentFlowStep
  values: PaymentFlowValues
}

interface PaymentFlowProps {
  initialValues: PaymentFlowValues
}

export function PaymentFlow({ initialValues }: PaymentFlowProps) {
  const [flow, setFlow] = useState<PaymentFlowState>({
    step: 'entry',
    values: initialValues,
  })

  const getPaidPayrun = useGetPaidPayrun(
    { payrunId: initialValues.payrunId },
    {
      enabled: initialValues.payrunId !== undefined,
    }
  )

  function update(values?: Partial<PaymentFlowValues>) {
    setFlow((prev) => ({ ...prev, values: { ...prev.values, ...values } }))
  }

  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.set('amount', String(flow.values.amount.amount))
    nextSearchParams.set('email', String(flow.values.email))
    nextSearchParams.set('reference', String(flow.values.billReference))
    setSearchParams(nextSearchParams)
  }, [
    flow.values.amount,
    flow.values.email,
    flow.values.billReference,
    searchParams,
    setSearchParams,
  ])

  function navigate(step: PaymentFlowStep, values?: Partial<PaymentFlowValues>) {
    setFlow((prev) => ({ step, values: { ...prev.values, ...values } }))
  }

  useEffect(() => {
    // TODO: wait for BE fix of paid payrun staying in the same status and with no paid at.
    // if (getPaidPayrun.data) {
    //   navigate('success', getPaidPayrun.data)
    // }
  }, [getPaidPayrun.data])

  switch (flow.step) {
    case 'entry':
      return (
        <EntryStep
          values={flow.values}
          onContinue={(values) => {
            update(values)
            navigate('payment-method')
          }}
        />
      )
    case 'payment-method':
      return (
        <PaymentMethodStep
          onContinue={(values) => {
            update(values)
            switch (values.paymentMethod) {
              case 'BANK':
                return navigate('pay-by-bank')
              case 'CARD':
                return navigate('pay-by-card')
              case undefined:
                throw new Error('Missing selected payment method')
              default:
                throw new ImpossibleError(
                  'Unhandled payment method',
                  values.paymentMethod
                )
            }
          }}
          onBack={() => {
            navigate('entry')
          }}
        />
      )
    case 'pay-by-bank':
      return (
        <PayByBankStep
          values={flow.values}
          onBack={() => {
            navigate('payment-method')
          }}
          onContinue={(values) => {
            update(values)
            // todo:initiate payment
            navigate('success') // temp
          }}
        />
      )
    case 'pay-by-card':
      return (
        <PayByCardStep
          values={flow.values}
          onBack={() => {
            navigate('payment-method')
          }}
          onContinue={(values) => {
            update(values)
            // todo:initiate payment
            navigate('success') // temp
          }}
        />
      )
    case 'success':
      return <SuccessResultStep values={flow.values} />
    case 'failure':
      return <FailureResultStep />
    default:
      throw new ImpossibleError('Unhandled step', flow.step)
  }
}
