import { theme } from '../../stitches'

export const Visa = () => (
  <svg width="36" height="18" viewBox="0 0 36 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00032 13.8625H6.23092L4.15422 5.70669C4.05565 5.33153 3.84636 4.99986 3.5385 4.84354C2.7702 4.4507 1.92358 4.13806 1 3.98039V3.66639H5.46127C6.07699 3.66639 6.53878 4.13806 6.61575 4.68586L7.69326 10.5689L10.4613 3.66639H13.1537L9.00032 13.8625ZM14.6926 13.8625H12.0771L14.2308 3.66639H16.8462L14.6926 13.8625ZM20.2312 6.48946C20.3081 5.94031 20.7699 5.62631 21.3087 5.62631C22.1553 5.54747 23.0775 5.70515 23.8472 6.09663L24.309 3.90136C23.5393 3.58737 22.6927 3.42969 21.9244 3.42969C19.3859 3.42969 17.5387 4.842 17.5387 6.8021C17.5387 8.29325 18.8471 9.0762 19.7707 9.54788C20.7699 10.0182 21.1547 10.3322 21.0778 10.8025C21.0778 11.508 20.3081 11.822 19.5398 11.822C18.6162 11.822 17.6927 11.5868 16.8474 11.194L16.3856 13.3906C17.3092 13.7821 18.3084 13.9398 19.232 13.9398C22.0783 14.0172 23.8472 12.6063 23.8472 10.4885C23.8472 7.82157 20.2312 7.66525 20.2312 6.48946ZM32.999 13.8625L30.9223 3.66639H28.6916C28.2298 3.66639 27.7681 3.98039 27.6141 4.4507L23.7686 13.8625H26.461L26.9984 12.3727H30.3065L30.6144 13.8625H32.999ZM29.0768 6.41106L29.8451 10.2538H27.6914L29.0768 6.41106Z"
      fill={theme.colors['grey-20'].toString()}
    />
  </svg>
)
