import { ExternalResource } from 'kitchen/constants'
import { Logo } from 'salad/components'
import { HStack, Text, VStack } from 'salad/primitives'

export const ApronFooter = () => {
  return (
    <VStack pb={24} pt={48} gap={16} css={{ justifyItems: 'center', alignSelf: 'end' }}>
      <Logo variant="full" />
      <HStack gap={16} css={{ justifyContent: 'center' }}>
        <Text variant="paragraph-14" as="a" href={ExternalResource.TERMS_AND_CONDITIONS}>
          Terms of service
        </Text>
        <Text variant="paragraph-14" as="a" href={ExternalResource.FAQ_SECURITY}>
          Security
        </Text>
        <Text variant="paragraph-14" as="a" href={ExternalResource.PRIVACY_POLICY}>
          Privacy
        </Text>
      </HStack>
    </VStack>
  )
}
