import { Routes, Route } from 'kitchen/router'
import { Match } from './constants'
import { IndexPage } from './features/index-page'
import { PaymentFlowPage } from './features/payment-flow'

export const GetPaidRoutes = () => (
  <Routes>
    <Route index element={<IndexPage />} />
    <Route path={Match.COMPANY_ALIAS_NAME} element={<PaymentFlowPage />} />
  </Routes>
)
