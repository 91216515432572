import { initRudderAnalytics } from 'domains/analytics/rudderstack'
import { useHandleVitePreloadError } from 'kitchen/hooks/use-handle-vite-preload-error'
import { Suspense } from 'react'
import { Delayed, Spinner, Status } from 'salad/components'
import { StatusLayout } from 'salad/layouts'
import { GetPaidProviders } from './get-paid-providers'
import { GetPaidRoutes } from './get-paid-routes'
import { globalStyles } from './global-styles'

if (['production'].includes(process.env.BUILD_MODE) && !process.env.PLAYWRIGHT) {
  initRudderAnalytics()
}

export function GetPaidApp() {
  useHandleVitePreloadError()
  globalStyles()
  return (
    <GetPaidProviders>
      <Suspense
        fallback={
          <Delayed delay={500}>
            <StatusLayout.Root>
              <StatusLayout.Content>
                <Status.Root>
                  <Status.Icon>
                    <Spinner size={64} color="grey-10" />
                  </Status.Icon>
                </Status.Root>
              </StatusLayout.Content>
            </StatusLayout.Root>
          </Delayed>
        }
      >
        <GetPaidRoutes />
      </Suspense>
    </GetPaidProviders>
  )
}
