import { PriceTag } from 'domains/money/components'
import { useMoneyFormat } from 'domains/money/hooks'
import { getTotalWithPaymentFees } from 'domains/payrun/utils'
import { useDateFormat } from 'kitchen/hooks/use-date-format'
import { getAccountTypeLabel } from 'kitchen/utils/accounts'
import { SerratedEdge, DataGroup, Separator } from 'salad/components'
import type { GridProps } from 'salad/primitives'
import { Flex, Grid, VStack, Text } from 'salad/primitives'
import { theme } from 'salad/stitches'
import type { PaymentFlowValues } from './types'

const PaymentSummaryWrapper = ({ children }: GridProps) => {
  return (
    <Grid
      css={{
        gridAutoRows: '1fr auto',
        filter: `drop-shadow(${theme.shadows['elevation-200']})`,
      }}
    >
      {children}
      <SerratedEdge position="bottom" />
    </Grid>
  )
}

interface PaymentSummaryCardProps {
  values: PaymentFlowValues
}

export const PaymentSummaryCard = ({ values }: PaymentSummaryCardProps) => {
  const moneyFormat = useMoneyFormat()
  const dateFormat = useDateFormat()

  const { totalWithPaymentFees, paymentFees } = getTotalWithPaymentFees({
    total: values.amount,
    accountType: values.paymentMethod ?? null,
    beneficiaries: [],
    grouped: true,
  })

  return (
    <PaymentSummaryWrapper>
      <VStack gap={24} p={32} css={{ backgroundColor: theme.colors.white }}>
        <Text variant="headline-h2">Payment</Text>
        <Separator variant="dotted" />
        <VStack
          gap={24}
          css={{
            '[data-group]': {
              gridAutoFlow: 'column',
              justifyContent: 'space-between',
            },
          }}
        >
          <DataGroup.Root>
            <DataGroup.Label>Reference</DataGroup.Label>
            <DataGroup.Value>{values.billReference}</DataGroup.Value>
          </DataGroup.Root>
          <DataGroup.Root>
            <DataGroup.Label>Paid to</DataGroup.Label>
            <DataGroup.Value>{values.beneficiaryCompany.legalName}</DataGroup.Value>
          </DataGroup.Root>
          <DataGroup.Root>
            <DataGroup.Label>Amount</DataGroup.Label>
            <DataGroup.Value>{moneyFormat.format(values.amount)}</DataGroup.Value>
          </DataGroup.Root>

          {paymentFees && values.paymentMethod && (
            <>
              {paymentFees.internationalTransfer.amount > 0 && (
                <DataGroup.Root>
                  <DataGroup.Label>International payment fee</DataGroup.Label>
                  <DataGroup.Value>
                    {moneyFormat.format(paymentFees.internationalTransfer)}
                  </DataGroup.Value>
                </DataGroup.Root>
              )}
              {paymentFees.processing.amount > 0 && (
                <DataGroup.Root>
                  <DataGroup.Label>
                    {getAccountTypeLabel(values.paymentMethod)} processing fee
                  </DataGroup.Label>
                  <DataGroup.Value>
                    {moneyFormat.format(paymentFees.processing)}
                  </DataGroup.Value>
                </DataGroup.Root>
              )}
            </>
          )}
        </VStack>
        <Separator variant="dotted" />

        <DataGroup.Root align="end">
          <DataGroup.Label>Total amount</DataGroup.Label>
          <DataGroup.Value>
            <PriceTag variant="medium" value={totalWithPaymentFees} />
          </DataGroup.Value>
        </DataGroup.Root>

        <Separator variant="dotted" />

        <Flex justify="between">
          {values.paymentMethod && (
            <DataGroup.Root>
              <DataGroup.Label>Payment method</DataGroup.Label>
              {(function renderPaymentMethod() {
                switch (values.paymentMethod) {
                  case 'CARD':
                    return <Text>Card</Text>
                  case 'BANK':
                    return <Text>Bank</Text>
                  default:
                    return null
                }
              })()}
            </DataGroup.Root>
          )}
          <Flex align="end">
            <Text variant="paragraph-16">Paid on {dateFormat.format(values.paidAt)}</Text>
          </Flex>
        </Flex>
        <SerratedEdge position="bottom" />
      </VStack>
    </PaymentSummaryWrapper>
  )
}
