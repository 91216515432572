import { Status } from 'salad/components'
import { StatusLayout } from 'salad/layouts'
import { Image } from 'salad/primitives'
import marioGif from './mario.gif'

export const IndexPage = () => {
  return (
    <StatusLayout.Root>
      <StatusLayout.Content>
        <Status.Root>
          <Status.Title>Get paid with Apron</Status.Title>
          <Status.Icon>
            <Image src={marioGif} width={400} height={400} />
          </Status.Icon>
        </Status.Root>
      </StatusLayout.Content>
    </StatusLayout.Root>
  )
}
