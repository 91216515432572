import { MoneyInput } from 'domains/money/components'
import { useCreateDraftGetPaidPayrun } from 'domains/payrun/queries'
import { Controller, useForm, yupResolver } from 'kitchen/forms'
import { useSearchParams } from 'kitchen/router'
import * as yup from 'kitchen/validations'
import { useId } from 'react'
import {
  InputGroup,
  Surface,
  ReferenceInput,
  Item,
  Avatar,
  Separator,
  Button,
  Toast,
} from 'salad/components'
import { StepLayout } from 'salad/layouts'
import { Box, Flex, Text, VStack } from 'salad/primitives'
import { ApronFooter } from '../components/apron-footer/apron-footer'
import type { EntryStepValues } from '../types'
import partnersImage from './get-paid-partners.png'

const validationSchema = yup.object({
  amount: yup.money().required(),
  billReference: yup.string().required('Required'),
})

interface EntryStepProps {
  values: EntryStepValues
  onContinue: (values: EntryStepValues) => void
}

export function EntryStep({ values, onContinue }: EntryStepProps) {
  const formId = useId()
  const toast = Toast.useContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const { handleSubmit, control, getValues } = useForm<EntryStepValues>({
    defaultValues: values,
    resolver: yupResolver(validationSchema),
  })

  const createDraftPayrun = useCreateDraftGetPaidPayrun({
    onSuccess: (payrunId) => {
      const nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set('payrunId', payrunId)
      setSearchParams(nextSearchParams)

      onContinue({ ...getValues(), payrunId })
    },
    onError: (_, variables) =>
      toast.show(
        <Toast.Root>
          <Toast.Title>Something went wrong</Toast.Title>
          <Toast.Action
            altText="Try again"
            onClick={() => createDraftPayrun.mutate(variables)}
          >
            Try again
          </Toast.Action>
        </Toast.Root>
      ),
  })

  function handleFormSubmit(values: EntryStepValues) {
    createDraftPayrun.mutate({
      item: {
        amount: values.amount,
      },
      reference: values.billReference,
      companyId: values.beneficiaryCompany.id,
    })
  }

  return (
    <StepLayout.Root size={512} css={{ gridTemplateRows: 'auto 1fr' }}>
      <VStack gap="32" as="form" id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
        <Surface variant="flat">
          <VStack>
            <Box p={24}>
              <Item.Root size="medium" css={{ minHeight: 62, pointerEvents: 'none' }}>
                <Item.Start>
                  <Avatar
                    variant="square"
                    name={values.beneficiaryCompany.legalName}
                    size="large"
                    aria-hidden
                  />
                </Item.Start>
                <Item.Content>
                  <VStack>
                    <Text variant="headline-h2">
                      {values.beneficiaryCompany.legalName}
                    </Text>
                    <Text variant="paragraph-16" color="black-alpha-60">
                      is requesting a payment
                    </Text>
                  </VStack>
                </Item.Content>
              </Item.Root>
            </Box>
            <Separator size={1} />
            <VStack p={32} gap={24}>
              <Controller
                control={control}
                name="amount"
                render={({ field, fieldState: { error } }) => (
                  <InputGroup.Root>
                    <InputGroup.Label>Amount</InputGroup.Label>
                    <MoneyInput
                      aria-label="Amount"
                      aria-invalid={error !== undefined}
                      placeholder={field.value}
                      autoComplete="off"
                      value={field.value}
                      onValueChange={(value) => {
                        field.onChange(value)
                      }}
                    />
                    {error && <InputGroup.Message>{error.message}</InputGroup.Message>}
                  </InputGroup.Root>
                )}
              />

              <Controller
                control={control}
                name="billReference"
                render={({ field, fieldState: { error } }) => (
                  <InputGroup.Root>
                    <InputGroup.Label>Invoice reference</InputGroup.Label>
                    <ReferenceInput
                      {...field}
                      currency={values.amount.currency}
                      autoComplete="off"
                      aria-invalid={error !== undefined}
                    />
                    {error && <InputGroup.Message>{error.message}</InputGroup.Message>}
                  </InputGroup.Root>
                )}
              />
            </VStack>
            <Separator size={1} />
            <Flex p={32} align="center" direction="column" gap={8}>
              <Button.Root form={formId} variant="main" size="large" type="submit">
                Pay invoice
              </Button.Root>
              <img
                width={212}
                alt="Visa, Mastercard, Paypal, American Express, Bank transfer"
                src={partnersImage}
              />
            </Flex>
          </VStack>
        </Surface>
      </VStack>
      <ApronFooter />
    </StepLayout.Root>
  )
}
