import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { CacheKey, Time } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import type { QueryHookFactory, MutationHookFactory } from 'kitchen/types'
import { publicApi } from 'kitchen/utils/api'
import {
  approvePayrun,
  createDraftPayrun,
  createDraftPayroll,
  createDraftManualPayment,
  declinePayrun,
  getPayrun,
  getPayrunEstimates,
  getPayrunPendingCount,
  getPayrunTopupOptions,
  updatePayrun,
  updatePayrunContributors,
  updatePayrunIsGrouped,
  getPendingPayruns,
  getPendingPayrunsStats,
  getPendingPayrunsSuggestions,
  getPayrunAuditLog,
  createBankPayment,
  startBankPayment,
  authorizeBankPayment,
  declineBankPayment,
  getGetPaidPayrun,
  createDraftGetPaidPayrun,
} from './requests'
import type {
  ApprovePayrunPayload,
  CreateDraftPayrunPayload,
  CreateDraftPayrollPayload,
  CreateDraftManualPaymentPayload,
  DeclinePayrunPayload,
  AuthorizeBankPaymentPayload,
  Payrun,
  PayrunContributors,
  PayrunEstimatesPayload,
  PayrunEstimatesResponse,
  PayrunPayload,
  PayrunPendingCountPayload,
  TopupOptionsPayload,
  UpdatePayrunContributorsPayload,
  UpdatePayrunIsGroupedPayload,
  UpdatePayrunPayload,
  PendingPayrunsPayload,
  PendingPayrunsStats,
  PendingPayrunsSuggestions,
  TopupOptionsResponse,
  PayrunAuditLog,
  PayrunAuditLogPayload,
  CreateBankPaymentPayload,
  CreateBankPaymentResponse,
  StartBankPaymentPayload,
  DeclineBankPaymentPayload,
  PayrunId,
  GetPaidPayrun,
  CreateDraftGetPaidPayrunPayload,
  GetPaidPayrunPayload,
} from './types'

export const usePayrun: QueryHookFactory<PayrunPayload, Payrun> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.PAYRUN, payload.payrunId],
    queryFn: ({ signal }) => getPayrun(fetch, payload, signal),
    ...options,
  })
}

export const useGetPaidPayrun: QueryHookFactory<GetPaidPayrunPayload, GetPaidPayrun> = (
  payload,
  options
) => {
  return useQuery({
    queryKey: [CacheKey.PAYRUNS, payload.payrunId],
    queryFn: ({ signal }) => getGetPaidPayrun(publicApi, payload, signal),
    ...options,
  })
}

/**
 * a mutation to refetch usePayrun
 */
export const useFetchPayrun: MutationHookFactory<PayrunPayload, Payrun> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => getPayrun(fetch, payload),
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Payrun>([CacheKey.PAYRUN, variables.payrunId], data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

/**
 * authenticated update including update of payment contacts.
 */
export const useUpdatePayrun: MutationHookFactory<UpdatePayrunPayload, Payrun> = (
  options
) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => updatePayrun(fetch, payload),
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Payrun>([CacheKey.PAYRUN, variables.payrunId], data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useUpdatePayrunContributors: MutationHookFactory<
  UpdatePayrunContributorsPayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()
  return useMutation({
    ...options,
    mutationFn: (payload) => updatePayrunContributors(fetch, payload),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.PAYRUN, variables.payrunId])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useApprovePayrun: MutationHookFactory<
  ApprovePayrunPayload,
  PayrunContributors
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => approvePayrun(fetch, payload),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([CacheKey.PAYRUN, variables.payrunId])
      queryClient.invalidateQueries([CacheKey.PENDING_PAYRUNS])

      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useCreateDraftPayrun: MutationHookFactory<
  CreateDraftPayrunPayload,
  Payrun
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => createDraftPayrun(fetch, payload),
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Payrun>([CacheKey.PAYRUN, data.id], data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useCreateDraftGetPaidPayrun: MutationHookFactory<
  CreateDraftGetPaidPayrunPayload,
  PayrunId
> = (options) => {
  return useMutation({
    ...options,
    mutationFn: (payload) => createDraftGetPaidPayrun(publicApi, payload),
  })
}

export const useCreateDraftPayroll: MutationHookFactory<
  CreateDraftPayrollPayload,
  Payrun
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => createDraftPayroll(fetch, payload),
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Payrun>([CacheKey.PAYRUN, data.id], data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useCreateDraftManualPayment: MutationHookFactory<
  CreateDraftManualPaymentPayload,
  Payrun
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => createDraftManualPayment(fetch, payload),
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Payrun>([CacheKey.PAYRUN, data.id], data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useCancelPayrun: MutationHookFactory<DeclinePayrunPayload, void> = (
  options?
) => {
  const fetch = useFetch()
  return useMutation({
    ...options,
    mutationFn: (payload) => declinePayrun(fetch, payload),
  })
}

export const useCancelPendingPayrun: MutationHookFactory<DeclinePayrunPayload, void> = (
  options
) => {
  const queryClient = useQueryClient()
  return useCancelPayrun({
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.PENDING_PAYMENTS])
      await queryClient.invalidateQueries([CacheKey.PENDING_PAYRUNS])
      await queryClient.invalidateQueries([CacheKey.PAYRUN, variables.payrunId])

      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useTopupOptions: QueryHookFactory<
  TopupOptionsPayload,
  TopupOptionsResponse
> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.TOPUP_OPTIONS, payload.accountId, payload.total],
    queryFn: ({ signal }) => getPayrunTopupOptions(fetch, payload, signal),
    ...options,
  })
}

export const usePayrunEstimates: QueryHookFactory<
  PayrunEstimatesPayload,
  PayrunEstimatesResponse
> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [
      CacheKey.PAYRUN_ESTIMATES,
      payload.accountId,
      payload.items
        .map((item) => `${item.amount.amount}-${item.amount.currency}-${item.invoiceId}`)
        .join('-'),
    ],
    queryFn: ({ signal }) => getPayrunEstimates(fetch, payload, signal),
    ...options,
  })
}

export const useUpdatePayrunIsGrouped: MutationHookFactory<
  UpdatePayrunIsGroupedPayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => updatePayrunIsGrouped(fetch, payload),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([CacheKey.PAYRUN, variables.payrunId])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const usePayrunPendingCount: QueryHookFactory<
  PayrunPendingCountPayload,
  number
> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [
      CacheKey.PAYRUN_PENDING_COUNT,
      payload.companyRefId,
      payload.beneficiaryId,
    ],
    queryFn: ({ signal }) => getPayrunPendingCount(fetch, payload, signal),
    enabled: payload.beneficiaryId !== undefined,
    ...options,
  })
}

export function usePendingPayruns(payload: PendingPayrunsPayload) {
  const fetch = useFetch()
  return useInfiniteQuery(
    [CacheKey.PENDING_PAYRUNS, payload],
    ({ pageParam, signal }) =>
      getPendingPayruns(fetch, { ...payload, page: pageParam }, signal),
    {
      select: (data) => ({
        pages: data.pages.map((item) => item.items),
        pageParams: data.pageParams,
        count: data.pages[0].count,
      }),
      getNextPageParam: (lastPage) => lastPage.page ?? undefined,
      keepPreviousData: true,
      cacheTime: Time.MINUTE,
      staleTime: Time.MINUTE,
    }
  )
}

export const usePendingPayrunsStats: QueryHookFactory<void, PendingPayrunsStats> = (
  options
) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.PENDING_PAYRUNS, CacheKey.STATS],
    queryFn: ({ signal }) => getPendingPayrunsStats(fetch, signal),
    ...options,
  })
}

export const usePendingPayrunsSuggestions: QueryHookFactory<
  void,
  PendingPayrunsSuggestions
> = (options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.PENDING_PAYRUNS, CacheKey.SUGGESTIONS],
    queryFn: ({ signal }) => getPendingPayrunsSuggestions(fetch, signal),
    ...options,
  })
}

export const usePayrunAuditLog: QueryHookFactory<
  PayrunAuditLogPayload,
  PayrunAuditLog
> = (payload, options) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [CacheKey.PAYRUN, payload.payrunId, CacheKey.AUDIT_LOG],
    queryFn: ({ signal }) => getPayrunAuditLog(fetch, payload, signal),
    ...options,
  })
}

export const useCreateBankPayment: MutationHookFactory<
  CreateBankPaymentPayload,
  CreateBankPaymentResponse
> = (options) => {
  const fetch = useFetch()
  return useMutation((payload) => createBankPayment(fetch, payload), options)
}

export const useStartBankPayment: MutationHookFactory<StartBankPaymentPayload, void> = (
  options
) => {
  const fetch = useFetch()
  return useMutation((payload) => startBankPayment(fetch, payload), options)
}

export const useAuthorizeBankPayment: MutationHookFactory<
  AuthorizeBankPaymentPayload,
  void
> = (options) => {
  const fetch = useFetch()
  return useMutation({
    mutationFn: (payload) => authorizeBankPayment(fetch, payload),
    ...options,
  })
}

export const useDeclineBankPayment: MutationHookFactory<
  DeclineBankPaymentPayload,
  void
> = (options) => {
  const fetch = useFetch()
  return useMutation((payload) => declineBankPayment(fetch, payload), options)
}
