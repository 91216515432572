import type { CardScheme } from 'api/types/cards'
import { ImpossibleError } from 'kitchen/utils/error'
import { Amex } from './amex'
import { MasterCard } from './master-card'
import { Visa } from './visa'

interface PaymentNetworkProps {
  scheme: CardScheme
}

export function PaymentNetworkIcon({ scheme }: PaymentNetworkProps) {
  switch (scheme) {
    case 'VISA':
      return <Visa />
    case 'MASTER_CARD':
      return <MasterCard />
    case 'AMEX':
      return <Amex />
    case 'UNKNOWN':
      return null
    default:
      throw new ImpossibleError('Unhandled payment network', scheme)
  }
}
